import React from 'react';

function Banner() {
  return (
    <div className='banner'>
        <div className="logo-container">
            <img src="img/banner.jpg" className="banner-logo" alt="Laundry Mates"/>
        </div>

    </div>
  );
}

export default Banner;



