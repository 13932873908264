import React from 'react';
import './App.css';


// import { Fade } from "react-awesome-reveal";

function ImagenConTitulo(props) {
  return (
    
    <div className="imagen-con-titulo">  
      <img src={props.imagenUrl} alt={props.titulo} />
      {/* <h2 className="titulo-services">
        <a href={props.pdfUrl} download={props.pdfFileName}>
          {props.titulo}
        </a>
      </h2> */}
    </div>
  );
}

function Machines() {
  const ourServices = [
    {
      imagenUrl: "img/machines/1.png",
    //   titulo: "Earn passive income"
    },
    {
      imagenUrl:"img/machines/2.png",
    //   titulo: "$0 upkeep costs"
    },
    {
      imagenUrl:"img/machines/3.png",
    //   titulo: "Free maintenance"
    },
    // {
    //     imagenUrl:"img/products/4.png",
    //   }
  ];

  return (
    <section className="machines" id="Machines">
      
      <div className='margin-title'>
        <div className='Our-Services'>VENDING MACHINES</div>
        <p className='our-services-txt'>balls 'N' all has a variety of bouncy ball vending machines available for siting on a profit share basis.
        </p>
        </div>
        

      {/* <Fade triggerOnce cascade delay={300} > */}
        <div className="imag-container">
        
          {ourServices.map((item, index) => (
            <ImagenConTitulo
              key={index}
              imagenUrl={item.imagenUrl}
              titulo={item.titulo}
            />
          ))}
        </div>

      {/* </Fade> */}
    </section>
  );
}

export default Machines;
