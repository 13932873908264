import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {BrowserRouter as Router, Routes, Route, Link, Switch, NavLink } from "react-router-dom";
import Home from './home';
import Header from './header';
import Footer from './footer';
import Services from './services';
import Machines from './machines';
import Products from './products';
import Gallery from './gallery';
import Contact from './contact';



function App() {
  return (
<Router>
    <Header />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/services" element={<Services />} />
      <Route path="/machines" element={<Machines />} />
      <Route path="/products" element={<Products />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
    <Footer />
  </Router>
  );
}

export default App;
