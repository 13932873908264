import React from 'react';
import Banner from './banner';
import Services from './services';
import Products from './products';
import Gallery from './gallery';
import Contact from './contact';
import Machines from './machines';



function Home() {
  return (
    <div>
        <Banner />
        <Machines />
        <Products />
        <Services />
        <Gallery />
        <Contact />
    </div>
  );
}

export default Home;
