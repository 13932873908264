import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const images = [
  'img/product/Disney1 01.jpg',
  'img/product/Disney1 02.jpg',
  'img/product/Disney1 03.jpg',
  'img/product/Disney1 04.jpg',
  'img/product/Disney1 05.jpg',
  'img/product/Disney1 06.jpg',
  'img/product/Disney1 07.jpg',
  'img/product/Disney1 08.jpg',
  'img/product/Disney1 09.jpg',
  'img/product/Disney1 10.jpg',
  'img/product/Disney1 all.jpg',
  'img/product/Disney2 01.jpg',
  'img/product/Disney2 02.jpg',
  'img/product/Disney2 03.jpg',
  'img/product/Disney2 04.jpg',
  'img/product/Disney2 05.jpg',
  'img/product/Disney2 06.jpg',
  'img/product/Disney2 07.jpg',
  'img/product/Disney2 08.jpg',
  'img/product/Disney2 09.jpg',
  'img/product/Disney2 all.jpg',
  'img/product/microballs 01.jpg',
  'img/product/microballs 02.jpg',
  'img/product/microballs 03.jpg',
  // 'img/product/microballs 04.jpg',
  'img/product/microballs 05.jpg',
  'img/product/microballs 06.jpg',
  'img/product/microballs 07.jpg',
  'img/product/microballs 08.jpg',
  'img/product/microballs 09.jpg',
  'img/product/microballs 10.jpg',
  'img/product/microballs 11.jpg',
  'img/product/microballs 12.jpg',
  'img/product/microballs 13.jpg',
  'img/product/microballs 14.jpg',
  'img/product/microballs 15.jpg',
  'img/product/microballs 16.jpg',
  'img/product/microballs 17.jpg',
  'img/product/microballs 18.jpg',
  'img/product/microballs 19.jpg',
  'img/product/microballs 20.jpg',
  'img/product/microballs 21.jpg',
  'img/product/microballs all.jpg',
  'img/product/miniballs 01.jpg',
  'img/product/miniballs 02.jpg',
  'img/product/miniballs 03.jpg',
  'img/product/miniballs 04.jpg',
  'img/product/miniballs 05.jpg',
  'img/product/miniballs 06.jpg',
  'img/product/miniballs 07.jpg',
  'img/product/miniballs 08.jpg',
  'img/product/miniballs 09.jpg',
  'img/product/miniballs 10.jpg',
  'img/product/miniballs 11.jpg',
  'img/product/miniballs 12.jpg',
  'img/product/miniballs 13.jpg',
  'img/product/miniballs 14.jpg',
  'img/product/miniballs 15.jpg',
  'img/product/miniballs 16.jpg',
  'img/product/miniballs 17.jpg',
  'img/product/miniballs 18.jpg',
  'img/product/miniballs 19.jpg',
  'img/product/miniballs 20.jpg',
  'img/product/miniballs 21.jpg',
  'img/product/miniballs 22.jpg',
  'img/product/miniballs 23.jpg',
  'img/product/miniballs 24.jpg',
  'img/product/miniballs 25.jpg',
  'img/product/miniballs 26.jpg',
  'img/product/miniballs 27.jpg',
  'img/product/miniballs all.jpg',
  'img/product/Pooh 01.jpg',
  'img/product/Pooh 02.jpg',
  'img/product/Pooh 03.jpg',
  'img/product/Pooh 04.jpg',
  'img/product/Pooh 05.jpg',
  'img/product/Pooh 06.jpg',
  'img/product/Pooh 07.jpg',
  'img/product/Pooh 08.jpg',
  'img/product/Pooh 09.jpg',
  'img/product/Pooh all.jpg',
  'img/product/Princess 01.jpg',
  'img/product/Princess 02.jpg',
  'img/product/Princess 03.jpg',
  'img/product/Princess 04.jpg',
  'img/product/Princess 05.jpg',
  'img/product/Princess 06.jpg',
  'img/product/Princess 07.jpg',
  'img/product/Princess 08.jpg',
  'img/product/Princess 09.jpg',
  'img/product/Princess all.jpg',
  'img/product/SuperBall6 01.jpg',
  'img/product/SuperBall6 02.jpg',
  'img/product/SuperBall6 03.jpg',
  'img/product/SuperBall6 04.jpg',
  'img/product/SuperBall6 05.jpg',
  'img/product/SuperBall6 06.jpg',
  'img/product/SuperBall6 07.jpg',
  'img/product/SuperBall6 08.jpg',
  'img/product/SuperBall6 09.jpg',
  'img/product/SuperBall6 10.jpg',
  'img/product/SuperBall6 11.jpg',
  'img/product/SuperBall6 12.jpg',
  'img/product/SuperBall6 13.jpg',
  'img/product/SuperBall6 14.jpg',
  'img/product/SuperBall6 15.jpg',
  'img/product/SuperBall6 16.jpg',
  'img/product/SuperBall6 17.jpg',
  'img/product/SuperBall6 18.jpg',
  'img/product/SuperBall6 19.jpg',
  'img/product/SuperBall6 20.jpg',
  'img/product/SuperBall6 21.jpg',
  'img/product/SuperBall6 22.jpg',
  'img/product/SuperBall6 all.jpg',
  'img/product/SuperBall7 01.jpg',
  'img/product/SuperBall7 02.jpg',
  'img/product/SuperBall7 03.jpg',
  'img/product/SuperBall7 04.jpg',
  'img/product/SuperBall7 05.jpg',
  'img/product/SuperBall7 06.jpg',
  'img/product/SuperBall7 07.jpg',
  'img/product/SuperBall7 08.jpg',
  'img/product/SuperBall7 09.jpg',
  'img/product/SuperBall7 10.jpg',
  'img/product/SuperBall7 11.jpg',
  'img/product/SuperBall7 12.jpg',
  'img/product/SuperBall7 13.jpg',
  'img/product/SuperBall7 14.jpg',
  'img/product/SuperBall7 15.jpg',
  'img/product/SuperBall7 16.jpg',
  'img/product/SuperBall7 17.jpg',
  'img/product/SuperBall7 18.jpg',
  'img/product/SuperBall7 19.jpg',
  'img/product/SuperBall7 20.jpg',
  'img/product/SuperBall7 21.jpg',
  'img/product/SuperBall7 22.jpg',
  'img/product/SuperBall7 23.jpg',
  'img/product/SuperBall7 24.jpg',
  'img/product/SuperBall7 25.jpg',
  'img/product/SuperBall7 26.jpg',
  'img/product/SuperBall7 27.jpg',
  'img/product/SuperBall7 28.jpg',
  'img/product/SuperBall7 29.jpg',
  'img/product/SuperBall7 30.jpg',
  'img/product/SuperBall7 31.jpg',
  'img/product/SuperBall7 32.jpg',
  'img/product/SuperBall7 all.jpg',
  'img/product/SuperBall8 01.jpg',
  'img/product/SuperBall8 02.jpg',
  'img/product/SuperBall8 03.jpg',
  'img/product/SuperBall8 04.jpg',
  'img/product/SuperBall8 05.jpg',
  'img/product/SuperBall8 06.jpg',
  'img/product/SuperBall8 07.jpg',
  'img/product/SuperBall8 08.jpg',
  'img/product/SuperBall8 09.jpg',
  'img/product/SuperBall8 10.jpg',
  'img/product/SuperBall8 11.jpg',
  'img/product/SuperBall8 12.jpg',
  'img/product/SuperBall8 13.jpg',
  'img/product/SuperBall8 14.jpg',
  'img/product/SuperBall8 15.jpg',
  'img/product/SuperBall8 16.jpg',
  'img/product/SuperBall8 17.jpg',
  'img/product/SuperBall8 18.jpg',
  'img/product/SuperBall8 19.jpg',
  'img/product/SuperBall8 20.jpg',
  'img/product/SuperBall8 21.jpg',
  'img/product/SuperBall8 22.jpg',
  'img/product/SuperBall8 23.jpg',
  'img/product/SuperBall8 24.jpg',
  'img/product/SuperBall8 25.jpg',
  'img/product/SuperBall8 26.jpg',
  'img/product/SuperBall8 all.jpg',




];

function Product() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);
  const [slidesToShow, setSlidesToShow] = useState(5);
  const [slidesToScroll, setSlidesToScroll] = useState(3); // Nuevo estado para slidesToScroll
  const scrollThumbRef = useRef(null);
  

  const updateScrollBar = () => {
    if (sliderRef.current) {
      const slideCount = images.length;
      const currentSlideIndex = currentSlide;

      
      const scrollThumb = scrollThumbRef.current;
      const scrollBar = scrollThumb.parentNode;
      const percent = (currentSlideIndex / (slideCount - slidesToShow)) * 100;

      // bar dont desapear 
      const thumbWidth = (scrollBar.offsetWidth / slideCount) * slidesToShow;
      const maxLeft = scrollBar.offsetWidth - thumbWidth;
      const left = (percent / 100) * maxLeft;

      scrollThumb.style.left = left + 'px';
    }
  };

  useEffect(() => {
    updateScrollBar();
  }, [currentSlide]);


  

  const updateCurrentSlide = (index) => {
    setCurrentSlide(index);

    // bar initial position
    const scrollBar = scrollThumbRef.current.parentNode;
    const percent = (index / (images.length - slidesToShow)) * 100;
    scrollThumbRef.current.style.left = `${(scrollBar.offsetWidth - scrollThumbRef.current.offsetWidth) * (percent / 100)}px`;
  };

  const handleMouseDown = (e) => {
    const scrollBar = scrollThumbRef.current.parentNode;
    const scrollThumb = scrollThumbRef.current;
    const startX = e.clientX - scrollThumb.getBoundingClientRect().left;

    const handleMouseMove = (e) => {
      const newLeft = e.clientX - startX - scrollBar.getBoundingClientRect().left;
      const percent = (newLeft / (scrollBar.offsetWidth - scrollThumb.offsetWidth)) * 100;
      const newIndex = Math.round((images.length - slidesToShow) * (percent / 100));
      sliderRef.current.slickGoTo(newIndex);
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    beforeChange: (current, next) => updateCurrentSlide(next),
  };

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1200) {
        setSlidesToShow(5);
        setSlidesToScroll(3);
      } else if (windowWidth >= 992) {
        setSlidesToShow(4);
        setSlidesToScroll(3);
      } else if (windowWidth >= 768) {
        setSlidesToShow(3);
        setSlidesToScroll(3);
      } else {
        setSlidesToShow(1);
        setSlidesToScroll(1); // Cuando el ancho de la pantalla es menor a 400px, establece slidesToScroll a 1
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section className="products-section" id="Products">
      <div className='margin-title'>
      <div className='Our-Services'>OUR PRODUCTS</div>
      </div>
      <p className='our-services-txt'>balls 'N' all supplies a variety of sizes and styles of high quality high bounce balls and vending machines.  The balls are not the cheap, use once and it breaks apart balls that are commonly available throughout New Zealand.  We import directly from a factory with the strictest quality control, environmental and labour standards.  These standards are independently audited.
        </p>
    <div className="image-carousel">
      <Slider ref={sliderRef} {...settings}>
        {images.map((image, index) => (
          <div key={index} className="image-slide">
            <div className="image-container">
              <img src={image} alt={`Image ${index}`} loading="lazy"/>
            </div>
          </div>
        ))}
      </Slider>
      <div className="scroll-bar" onMouseDown={handleMouseDown}>
        <div className="scroll-thumb" ref={scrollThumbRef} />
      </div>
    </div>
    </section>
  );
}

export default Product;
