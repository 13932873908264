import React from 'react';
import './App.css';


import { Fade } from "react-awesome-reveal";

function ImagenConTitulo(props) {
  return (
    
    <div className="imagen-con-titulo">  
      <img src={props.imagenUrl} alt={props.titulo} />
      <h2 className="titulo-services">
        <a href={props.pdfUrl} download={props.pdfFileName}>
          {props.titulo}
        </a>
      </h2>
    </div>
  );
}

function Services() {
  const ourServices = [
    {
      imagenUrl: "img/services/services1.png",
      titulo: "Free maintenance"
    },
    {
      imagenUrl:"img/services/services2.png",
      titulo: "$0 upkeep costs"
    },
    {
      imagenUrl:"img/services/services3.png",
      titulo: "Earn passive income"
    }
  ];

  return (
    <section className="ourServices" id="Services">
        <div className='Our-Services'>OUR SERVICES</div>
        <p className='our-services-txt'>Since 2006, balls 'N' all has provided quality high bounce balls and machines to enable New Zealanders, both young and old, to enjoy an almost perfect toy.
A bouncy ball can be described as a spherical toy ball make of elastic material which allows it to bounce against hard services.  Alternatively, you can use the balls 'N' all definition - a small ball of pure fun!
If you have the perfect location and would like to provide a space on a profit share basis, please get in contact via the form below.
        </p>

      <Fade triggerOnce cascade delay={300} >
        <div className="imag-container">
        
          {ourServices.map((item, index) => (
            <ImagenConTitulo
              key={index}
              imagenUrl={item.imagenUrl}
              titulo={item.titulo}
            />
          ))}
        </div>
      </Fade>
    </section>
  );
}

export default Services;
