import React from 'react';


function Footer() {
  return (
    <footer>
      <div>
        <ul className='list-unstyled d-flex justify-content-center flex-wrap links-container text-center' id="listFooter">
          <li className="link-item">
            <a href='/' style={{ textDecoration: 'none' }} className='linkFooter'>
              HOME
            </a>
          </li>



          <li className="link-item">
            <a href='/#Machines' style={{ textDecoration: 'none' }} className='linkFooter'>
              MACHINES
            </a>
          </li>

          <li className="link-item">
            <a href='/#Products' style={{ textDecoration: 'none' }} className='linkFooter'>
              PRODUCTS
            </a>
          </li>

          <li className="link-item">
            <a href='/#Services' style={{ textDecoration: 'none' }} className='linkFooter'>
              SERVICES
            </a>
          </li>

          <li className="link-item">
            <a href='/#Gallery' style={{ textDecoration: 'none' }} className='linkFooter'>
              GALLERY
            </a>
          </li>


        </ul>
      </div>

      <div className='text-center p-3' style={{ backgroundColor: '#471f86', color: 'white' }}>
   
          Website crafted by <a href="https://www.natalilarroza.com" target="_blank" style={{ color: 'white', textDecoration: 'none', fontWeight: "bold" }}> Natali Larroza </a>| &copy; {new Date().getFullYear()} Ball 'N' All

      </div>

    </footer>
  );
}

export default Footer;
