import React, { useState, useEffect } from "react";
import { Carousel, Modal } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";





function Gallery() {
  const [modalShow, setModalShow] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [imagesToShow, setImagesToShow] = useState(8);
  const [showLess, setShowLess] = useState(false); 

  const handleCloseModal = () => {
    setModalShow(false);
  };

  const handleOpenModal = (index) => {
    setSelectedImageIndex(index);
    setModalShow(true);
  };

  const handleLoadMore = () => {
    setImagesToShow(imagesToShow + 8);
    if (imagesToShow + 8 >= lowResImages.length) {
      setShowLess(true); // Oculta el botón "Ver Más" cuando se muestran todas las imágenes
    }
  };

  const handleShowLess = () => {
    setImagesToShow(8);
    setShowLess(false);
  };


  const lowResImages = [
    { id: 1, src: "img/gallery/1.jpg", alt: "" },

    { id: 3, src: "img/gallery/3.jpg", alt: "" },
    { id: 4, src: "img/gallery/4.jpg", alt: "" },
    { id: 5, src: "img/gallery/5.jpg", alt: "" },
    { id: 6, src: "img/gallery/6.jpg", alt: "" },
    { id: 2, src: "img/gallery/2.jpg", alt: "" },
    { id: 7, src: "img/gallery/7.jpg", alt: "" },
    // { id: 8, src: "img/gallery/8.jpg", alt: "" },
    { id: 9, src: "img/gallery/9.jpg", alt: "" },
    { id: 10, src: "img/gallery/10.jpg", alt: "" },
    { id: 11, src: "img/gallery/11.jpg", alt: "" },
    { id: 12, src: "img/gallery/12.jpg", alt: "" },
    { id: 13, src: "img/gallery/13.jpg", alt: "" },
  ];


  return (
    <section className="gallery-section" id="Gallery">
      <div className='margin-title'>
      <div className='Our-Services'>PHOTO GALLERY</div>
      </div>
      <div className="GalleryHire">
        {lowResImages.slice(0, imagesToShow).map((image, index) => (
          <div className="responsive" key={image.id || index}>
            <div className="gallery">
              <a onClick={() => handleOpenModal(index)}>
                <img
                  src={image.src}
                  alt={image.alt}
                  width="700px"
                  height="700px"
                />
              </a>
            </div>
          </div>
        ))}
        <div className="button-position">
        {imagesToShow < lowResImages.length && !showLess && (
          <button className="load-more-button" onClick={handleLoadMore}>
            Show More
          </button>
        )}
        {showLess && (
          <button className="load-more-button" onClick={handleShowLess}>
            Show Less
          </button>
        )}
        </div>
    {/* </div> */}

<Modal show={modalShow} onHide={handleCloseModal}>
  <Modal.Header closeButton>
    <Modal.Title>{lowResImages[selectedImageIndex].alt}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Carousel
      activeIndex={selectedImageIndex}
      onSelect={(index) => setSelectedImageIndex(index)} 
    >
      {lowResImages.map((lowResImages, index) => (
        <Carousel.Item key={lowResImages.id}>
          <img
            className="d-block w-100"
            src={lowResImages.src}
            alt={lowResImages.alt}
            style={{
              cursor: "pointer",
              maxHeight: "700px",
              objectFit: "contain",
            }}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  </Modal.Body>
  <Modal.Footer>
    <button className="close" onClick={handleCloseModal}>Close</button>
  </Modal.Footer>
</Modal>


    </div>
    </section>
  );
}

export default Gallery;


